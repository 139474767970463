import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        I am a graduate-level Mental Health Intern under clinical supervision. My passion is to assist people challenged by trauma, PTSD, and neurodivergence to heal and flourish in their lives. As a woman of color and daughter of immigrants, I understand the complexity of balancing two sets of cultural norms while trying to define your own identity between them. My primary focus is working with women of color and first- and second-generation immigrants. However, I welcome people of all demographics to collaborate with me to find empowerment and peace.
        </p>
        <p>
        Being bi-cultural provides me with insight into what other bi-cultural people may be experiencing. I use my own lived experience as well as specialized training in working with BIPOC clients to help support you and validate your story. Multicultural theory is the foundation of my practice and allows me to see each client as a unique individual.
        </p>
        <p>
        I believe that each person deserves a safe space in which to explore and gain understanding of themselves and their patterns in order to to find healing and empowerment. I will meet you with unwavering empathy, authenticity, and respect as we collaborate to help you reach your goals. 
        </p>

        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
