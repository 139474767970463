import styled from "styled-components";

const Footer = styled.footer`
  background: #2f3630;
  color: #f4e8d3;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.87;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 30px;

  ul {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 37px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  ul a {
    text-decoration: none;
    color: #F4E8D3;
  }

  ul li {
    cursor: pointer;
  }

  ul li:hover {
    opacity: 0.8;
  }

  ul li:active {
    opacity: 0.5;
  }

  p {
    color: #f4e8d3;
  }

  .upper-wrap {
    display: flex;
    width: 1271px;
    max-width: 100%;
    justify-content: space-between;
  }

  .lower-wrap {
    margin-top: 150px;
    display: flex;
    width: 1271px;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  #socials {
    display: flex;
  }

  #socials img {
    margin: 10px;
  }

  @media screen and (max-width: 970px) {
    padding: 50px 30px;

    .upper-wrap {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .upper-wrap img {
      margin-bottom: 40px;
    }
    ul {
      display: block;
    }
    ul li:nth-of-type(5) {
      margin-bottom: 100px;
    }
  }

  @media screen and (max-width: 480px) {
    .lower-wrap {
      flex-direction: column-reverse;
    }
    #socials {
      margin-bottom: 20px;
    }
  }
`;

const FooterComponent = ({ menuOpen, setMenuOpen }) => {
  return (
    <Footer>
      <div className="upper-wrap">
        <a href="/">
          <img
            className="hover-effect"
            src="images/logo-footer.svg"
            alt="zendo logo"
          />
        </a>
        <div className="nav-menu">
          <ul>
            <li><a href="/staff">Staff</a></li>
            <li><a target="_blank" href="https://oregondirections.bamboohr.com/careers/50">Careers</a></li>
            <li><a href="/contact">Contact</a></li>
            <li>About</li>
            <li>Privacy Policy</li>
            <li>Terms & Conditions</li>
          </ul>
        </div>          
      </div>
      <div className="lower-wrap">
        <p>©2024 Zendo Health Company</p>
        <div id="socials">
          <img src="images/social/youtube.svg" alt="icon" />
          <img src="images/social/instagram.svg" alt="icon" />
          <img src="images/social/tiktok.svg" alt="icon" />
          <img src="images/social/linkedin.svg" alt="icon" />
        </div>
      </div>
    </Footer>
  );
};

export default FooterComponent;
