import React from "react";
import { useProgress } from "../../contexts/ProgressContext";
import styled from "styled-components";
import ButtonPlain from "../ButtonPlain";
import MedicaidUtahDetails from "./MedicaidUtahDetails"; 
import MedicareUtahDetails from "./MedicareUtahDetails"; 
import OutOfPocketDetailsUtah from "./OutOfPocketDetailsUtah"; 
import CommercialDetailsUtah from "./CommercialDetailsUtah"; 

const ArrowLink = styled.a`
  color: #5e8a64;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;

  position: relative;
  padding-right: 40px;
  cursor: pointer;
  text-decoration: none;

  &::after {
    content: url("../images/arrow.svg");
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: right ease 0.4s;
  }

  &:hover {
    opacity: 0.8;
    &::after {
      right: -10px;
    }
  }
  &:active {
    opacity: 0.5;
  }
`;

const PaymentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  .dba {
    color: #5e8a64;
    margin-bottom: 0px;
 }

  h3 {
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
  }
  .paying {
    font-size: 24px;
    color: black;
  }

  .payment-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 20px;
    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;

      .ohp-option {
        grid-row-start: 2;
        padding-bottom: 10px;
      }
    }
  }

  .button-plain {
    font-weight: bold; 
    padding: 10px 20px;
    border-radius: 5px; 
    &:hover {
      background-color: #f2f2f2; 
    }
  }
  .description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-align: center;
  }
  button {
    width: 240px;
  }
`;

const PaymentUtah = () => {
  const { nextStep, setPayingBy } = useProgress();

  const medicaidUtahClicked = () => {
    setPayingBy("medicaid");
    nextStep(<MedicaidUtahDetails />, 60);
  };

  const medicareUtahClicked = () => {
    setPayingBy("medicaid");
    nextStep(<MedicareUtahDetails />, 60);
  };

  const outOfPocketUtahClicked = () => {
    setPayingBy("out of pocket");
    nextStep(<OutOfPocketDetailsUtah />, 60);
  };

  const commercialUtahClicked = () => {
    setPayingBy("out of pocket");
    nextStep(<CommercialDetailsUtah />, 60);
  };

  return (
    <PaymentWrap>
      <h3>We are doing business in Utah as</h3>
      <h2 className="dba">Zendo Health Utah</h2>
      <h3 className="paying">How will you be paying?</h3>

      <div className="payment-options">
        <ButtonPlain onClick={commercialUtahClicked}>Insurance</ButtonPlain>
        <ButtonPlain onClick={outOfPocketUtahClicked}>Out of pocket</ButtonPlain>
        <div className="description ohp-option">Small co-payment</div>
        <div className="description">Cash or Credit Card</div>
      </div>
      <br/>
      <div className="payment-options">
        <ButtonPlain onClick={medicaidUtahClicked} dark>Medicaid</ButtonPlain>
        <ButtonPlain onClick={medicareUtahClicked} dark>Medicare</ButtonPlain>
        <div className="description ohp-option">No cost to you</div>
        <div className="description ohp-option">No cost to you</div>
      </div>

    </PaymentWrap>
  );
};

export default PaymentUtah;
